<!--
 * @Author: your name
 * @Date: 2022-02-13 21:55:38
 * @LastEditTime: 2022-02-13 22:05:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /iot-product-tool/src/pages/factory/quality/createOrder.vue
-->
<template>
    <div class="page-factory-order-create">
        <div class="list-box">
            <div class="input-item">
                <div class="item-title">*1.设备编号</div>
                <div class="item-input">
                    <Field
                        v-model="uniqueId"
                        label=""
                        placeholder="请输入设备编号"
                        input-align="left"
                        right-icon="scan"
                        maxlength="8"
                        type="number"
                        @click-right-icon="onClickIcon(1)"
                    />
                </div>
            </div>

            <div class="input-item">
                <div class="item-title">*2.主板编码</div>
                <div class="item-input">
                    <Field
                        v-model="barCode"
                        label=""
                        placeholder="请输入主板编码"
                        input-align="left"
                        right-icon="scan"
                        maxlength="13"
                        type="string"
                        @click-right-icon="onClickIcon(2)"
                    />
                </div>
            </div>
        </div>

        <div class="page-footer">
            <div class="footer-btn">取  消</div>
            <div class="footer-btn pass" v-if="uniqueId && uniqueId.length == 8" @click="onclickCreateBtn">确认创建</div>
            <div class="footer-btn" v-else>确认创建</div>
        </div>
    </div>
</template>

<script>
import { Field, Dialog, Toast } from 'vant'
export default {
    name: "DeviceCreateOrder",
    data() {
        return {
            uniqueId: '',
            barCode: ''
        };
    },
    components: {
        Field
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        vm.name = name
        if (!name) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return;
        }
        vm.weixinInit()
    },
    methods: {
        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ["scanQRCode"]
                });
            });
        },
        // 1：机身编码，2：主板码
        onClickIcon(type) {
            const vm = this;
            const scanType = []
            scanType.push(type == 1 ? 'qrCode' : 'barCode')
            window.wx.scanQRCode({
                needResult: 1,
                scanType,
                success: function(res) {
                    if (type == 2) {
                        alert(res.resultStr)
                        if (!(res.resultStr && res.resultStr.length >= 10)) {
                            Dialog.confirm({
                                title: "提示",
                                message: "非设备主板码~",
                                confirmButtonColor: "#32BE32",
                                showCancelButton: false
                            });
                        } else {
                            vm.barCode = res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr
                        }
                        return
                    }
                    const result = res.resultStr && res.resultStr.split('deviceId=')[1];
                    if (!result) {
                        Dialog.confirm({
                            title: "提示",
                            message: "非设备编号~",
                            confirmButtonColor: "#32BE32",
                            showCancelButton: false
                        });
                        return;
                    }

                    vm.$http({
                        type: "get",
                        url: `${vm.$config.base.BASE_URL}admin/iotTool/product/device/${result}/isNone`,
                        except: true
                    }).then(res => {
                        const { code, data, message } = res;
                        if (code != 1 || !data.length) {
                            Toast.fail({
                                message: message ||  data.length ? '服务器繁忙~' : '设备不存在',
                                forbidClick: false,
                                overlay: true,
                                duration: 2000,
                            });
                            return
                        }
                        vm.uniqueId = data[0].unique_id
                    }).catch(err => {
                        console.log(err);
                    })
                }
            });
        },

        onclickCreateBtn() {
            const vm = this
            vm.tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                type: "post",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/ruiy`,
                data: {
                    password: vm.$config.base.password,
                    operator: vm.name,
                    mainboardId: vm.barCode,
                    uniqueId: vm.uniqueId
                },
                except: true,
            }).then(res => {
                const { code, message, data } = res
                vm.tt.clear();
                if (code == 1) {
                    Toast.success({
                        message: '返厂单创建成功',
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                    setTimeout(() => {
                        vm.$router.push({
                            path: `/factory/quality/history/${data.uniqueId}?id=${data.id}`
                        });
                    }, 2000);
                } else {
                    Toast.fail({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 2000
                    });
                }
            }).catch(() => {
                vm.tt.clear();
            })
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-factory-order-create {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    font-size: 0.2rem;
    .list-box {
        background: @blank;
        padding: 0 20px;
        color: @textColor1;
        .input-item {
            padding: 20px 0;
            .item-title {
                padding-bottom: 20px;
            }

            .item-input {
                border-bottom: 1px solid @textColor1;
                .van-field {
                    padding-left: 0;
                    padding-right: 0;
                    .van-icon-scan {
                        font-size: 0.28rem;
                        color: @themeColor;
                    }

                    input {
                        font-size: 0.22rem;
                    }
                }
            }
        }
    }

    .page-footer {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 0.18rem;
        font-weight: bold;
        .footer-btn {
            width: 100px;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            border: 1px solid @textColor1;
            &.pass {
                color: @themeColor;
                border-color: @themeColor;
            }
        }
    }
}
</style>
